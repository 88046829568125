import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import {
  PackageFullModel,
  AlertsService,
  PackageService,
  SessionInfoService,
  CustomRuleService,
  PackageLibraryRuleModel,
  SessionKey,
  RuleCode,
} from 'degarmo-lib';

@Injectable({ providedIn: 'root' })
export class PackageBuilderResolver {
  constructor(
    private alertsService: AlertsService,
    private packageService: PackageService,
    private sessionInfoService: SessionInfoService,
    private customRuleService: CustomRuleService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PackageFullModel | Observable<PackageFullModel> | Promise<PackageFullModel> {
    return this.loadPackageData();
  }

  loadPackageData = (): Observable<PackageFullModel> => {
    const companyID: number = this.sessionInfoService.getConstant(SessionKey.PackageBuilderCompanyID, 'number', true);
    const packageID: number = this.sessionInfoService.getConstant(SessionKey.PackageIDToEdit, 'number', true);
    this.sessionInfoService.removeConstant(SessionKey.PrevPackageBuilderData);
    return new Observable<PackageFullModel>((subscriber) => {
      const promises: any = {
        rules: this.customRuleService.getRules({
          companyID: companyID,
          sets: ['Packages', 'AllowInterviewBuilder', 'ProductUsage'],
        }),
      };

      if (packageID) promises.packageDetails = this.packageService.getPackageFullDisplayDetails(packageID);

      forkJoin(promises).subscribe({
        next: (res: any) => {
          const priorities: string[] = ['companyID'];
          const rules: PackageLibraryRuleModel = {
            useAssessment: this.customRuleService.getPrioritizedRuleValue(RuleCode.UseAssessment, priorities, '0', res.rules) == 1 ? true : false,
            useInterviewBuilder:
              this.customRuleService.getPrioritizedRuleValue(RuleCode.AllowInterviewBuilder, priorities, '0', res.rules) == 1 ? true : false,
            maximumAssessmentPackages: Number(
              this.customRuleService.getPrioritizedRuleValue(RuleCode.MaximumAssessmentPackages, priorities, '10', res.rules)
            ),
            maximumInterviewPackages: Number(
              this.customRuleService.getPrioritizedRuleValue(RuleCode.MaximumInterviewPackages, priorities, '10', res.rules)
            ),
          };
          this.sessionInfoService.setConstant(SessionKey.PackageLibraryRules, rules, true);

          if (res.packageDetails) {
            this.sessionInfoService.setConstant(SessionKey.PackageBuilderData, res.packageDetails, true);
            this.sessionInfoService.setConstant(SessionKey.PrevPackageBuilderData, res.packageDetails, true);

            subscriber.next(res.packageDetails);
          } else {
            if (!this.sessionInfoService.getConstant(SessionKey.PackageBuilderData, 'object', true)) {
              const packageFull: PackageFullModel = {
                aorID: 0,
                combinedScoring: false,
                companyID: 0,
                customForms: [],
                cutScores: [],
                hasDigitalInterviewProduct: false,
                isJobMatch: false,
                languageID: 1,
                languageName: 'English',
                packageLanguages: [],
                locations: [],
                addCombinedPhases: [],
                addLocations: [],
                combinedPhases: '',
                removeCombinedPhases: [],
                removeLocations: [],
                packageID: 0,
                packageName: null,
                packageDescription: null,
                packageTypes: [],
                phases: [],
                phaseCount: 0,
                reportOptions: [],
                statusID: 1,
              };

              this.sessionInfoService.setConstant(SessionKey.PackageBuilderData, packageFull, true);
              subscriber.next(packageFull);
            } else {
              subscriber.next(this.sessionInfoService.getConstant(SessionKey.PackageBuilderData, 'object', true));
            }
          }

          subscriber.complete();
        },
      });
    });
  };
}
